import * as React from "react";
import RoundedButton from '../sub-components/roundedButton';
import * as styles from './imageTextSplit.module.scss';

const ImageTextSplit = ({ title, text, imgFirst, imgSrc, version }) => {
    return (
        <>
            {text && imgSrc &&
                <div className={[styles.container, imgFirst ? styles.imageFirst : styles.imageSecond].join(' ')}>
                    <Image imgSrc={imgSrc} />
                    <Overview title={title} imgFirst={imgFirst} text={text} />
                </div>
            }
        </>
    );
}

const Image = ({ imgSrc }) => {
    return (
        <div className={styles.imgWrap}>
            <div className={styles.imgContainer}>
                <img loading="lazy" className={styles.fgImg} src={imgSrc} alt="Home investment"></img>
            </div>
        </div>);
}

const Overview = ({ title, text }) => {
    return (
        <div className={styles.overviewWrap}>
            <div className={styles.overviewTitle}>{title}</div>
            <div className={styles.overviewLine}></div>
            <div className={styles.overviewContent} dangerouslySetInnerHTML={{__html: text}}></div>
            <Buttons />
        </div>
    );
}

const Buttons = () => {
    return (
        <div className={styles.buttonRow}>
            <RoundedButton color="blueToWhite" text="Primary Button" destination="https://google.com" />
            <RoundedButton color="whiteToBlue" text="Secondary Button" destination="https://google.com" />
        </div>
    );
}

export default ImageTextSplit;