import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import LoanOfficerHero from "../components/loanOfficerHero";
import ImageTextSplit from "../components/imageTextSplit";
import MeetTheTeam from "../components/meetTheTeam";
import StackedInformationalSection from "../components/stackedInformationalSection";
import ReviewsCarousel from "../components/reviewsCarousel";
import ContactLoanOfficer from "../components/contactLoanOfficer";
import Footer from "../components/footer";
import Seo from "../components/seo";

export const query = graphql`
query loanOfficerPage($loanOfficerId: DirectusData_GraphQLStringOrFloat) {
  directus {
    aboutNovus {
      shortDescriptionTitle
      shortDescription
    }
    exploreLoanCalculators {
      buttonOneText
      buttonOneLink
      title
      description
    }
    loanOfficer(filter: {id: {_eq: $loanOfficerId}}) {
      firstName
      lastName
      title
      phoneNumber
      email
      nmlsNumber
      shortDescription
      description
      meetTheTeamDescription
      applyLink
      continueApplicationLink
      currentBookingsLink
      facebook
      instagram
      linkedin
      twitter
      homeBot
      useLegacyUrl
      externalDomain
      domainUserName
      legacyUrl
      image {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      branch {
        name
        addressLineOne
        addressLineTwo
        city
        state
        postalCode
      }
      teamMembers {
        teamMember_id {
          status
          title
          firstName
          lastName
          headshot {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      reviews
    }
  }
}
`;

export default function LoanOfficerTemplate({ data }) {
  const officer = data.directus.loanOfficer[0];
  const aboutNovus = data.directus.aboutNovus;
  const exploreLoanCalculators = data.directus.exploreLoanCalculators;
  const fullName = `${officer.firstName} ${officer.lastName}`;
  const ogDescription = `Connect with ${fullName} today to find a home loan.`
  const headshotImage = officer?.image?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  const version = 2;

  const officerContact = {
    phoneNumbers: {
      office: officer.phoneNumber,
    },
    email: officer.email
  };

  const officerSocial = {
    facebook: officer.facebook,
    instagram: officer.instagram,
    twitter: officer.twitter,
    linkedin: officer.linkedin
  };

  return (
    <>
      <Helmet>
        <title>Novus Home Mortgage</title>
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Nunito+Sans"
        />
      </Helmet>
      <Seo
        title={fullName}
        description={ogDescription}
        image={headshotImage} />
      <div className="layout">
        <Header
          phoneNumber={officer.phoneNumber}
          email={officer.email}
          isLoanOfficerPage={true}
          homeBotLink={officer.homeBot} />
        <main>
          <section className="main-content">
            <div className="container">
              <div>
                <LoanOfficerHero loanOfficer={officer} version={version} />
                <ImageTextSplit
                  title={`About ${officer.firstName}`}
                  text={officer.shortDescription}
                  imgFirst={true}
                  imgSrc={"/assets/loanOfficerOverviews/loanOfficer_1.png"} version={version} />
                <ImageTextSplit
                  title={aboutNovus.shortDescriptionTitle}
                  text={aboutNovus.shortDescription}
                  imgFirst={false}
                  imgSrc={"/assets/loanOfficerOverviews/loanOfficer_2.png"} version={version} />
                <MeetTheTeam loanOfficer={officer} version={version} />
                <ReviewsCarousel loanOfficer={officer} version={version} />
                <StackedInformationalSection 
                  title={exploreLoanCalculators.title}
                  description={exploreLoanCalculators.description}
                  buttonOneText={exploreLoanCalculators.buttonOneText}
                  buttonOneLink={exploreLoanCalculators.buttonOneLink}
                  version={version}
                  loanOfficer={officer}/> 
                <ContactLoanOfficer loanOfficer={officer} version={version} />
              </div>
            </div>
          </section>
        </main>
        <Footer
          address={officer.branch}
          contact={officerContact}
          social={officerSocial} />
      </div>
    </>
  );
}

