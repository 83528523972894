// extracted by mini-css-extract-plugin
export var meetTheTeamV2 = "meetTheTeam-module--meetTheTeamV2--la-YP";
export var container = "meetTheTeam-module--container--RvmKb";
export var wrap = "meetTheTeam-module--wrap--SHr6j";
export var row1 = "meetTheTeam-module--row1--zaXup";
export var leftContent = "meetTheTeam-module--leftContent--uxn9N";
export var leftText = "meetTheTeam-module--leftText--X5uJ0";
export var greenLine = "meetTheTeam-module--greenLine--3hRxM";
export var rightContent = "meetTheTeam-module--rightContent--AWbUF";
export var rightText = "meetTheTeam-module--rightText--HmcFS";
export var buttonRow = "meetTheTeam-module--buttonRow--aoPcU";
export var row2 = "meetTheTeam-module--row2--FpSv0";
export var imgContainer = "meetTheTeam-module--imgContainer--8m97J";
export var teamMember = "meetTheTeam-module--teamMember--7yZ+9";
export var name = "meetTheTeam-module--name--BNhTI";
export var title = "meetTheTeam-module--title--PtkC6";
export var meetTheTeamV3 = "meetTheTeam-module--meetTheTeamV3---Iidc";