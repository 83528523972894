// extracted by mini-css-extract-plugin
export var container = "imageTextSplit-module--container--rmE8v";
export var imgWrap = "imageTextSplit-module--imgWrap--3ZZJx";
export var imgContainer = "imageTextSplit-module--imgContainer--viiAK";
export var fgImg = "imageTextSplit-module--fg-img--r7EaC";
export var overviewWrap = "imageTextSplit-module--overviewWrap--GvfAd";
export var overviewTitle = "imageTextSplit-module--overviewTitle--hxqBb";
export var overviewLine = "imageTextSplit-module--overviewLine--3X0-5";
export var overviewContent = "imageTextSplit-module--overviewContent--7JRwI";
export var buttonRow = "imageTextSplit-module--buttonRow--mnvTN";
export var imageFirst = "imageTextSplit-module--imageFirst--NEBVM";
export var imageSecond = "imageTextSplit-module--imageSecond--iIil5";