import * as React from "react";
import RoundedButton from '../sub-components/roundedButton';
import * as styles from './meetTheTeam.module.scss';

const MeetTheTeam = ({ loanOfficer, version }) => {

    const teamMembers = loanOfficer.teamMembers;
    if (version == 2){
        return (
            <>
                {teamMembers && teamMembers.length > 0 &&
                <div className={styles.meetTheTeamV2}>
                    <div className={styles.container}>
                        <div className={styles.wrap}>
                            <div className={styles.row1}>
                                <div className={styles.leftContent}>
                                    <div className={styles.leftText}>Meet <br></br>The Team</div>
                                    <div className={styles.greenLine}></div>
                                </div>
                                <div className={styles.rightContent}>
                                    <div className={styles.rightText} dangerouslySetInnerHTML={{__html: loanOfficer.meetTheTeamDescription}}></div>
                                    <div className={styles.buttonRow}>
                                        {/*<RoundedButton color="greenToBlue" text="More About The Team" destination="https://google.com" />*/}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row2}>
                                {teamMembers.map((teamMember, index) => (
                                    <div className={styles.imgContainer}>
                                        <img src={teamMember?.teamMember_id?.headshot?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={`${teamMember.teamMember_id.firstName} ${teamMember.teamMember_id.lastName}`} />
                                        <div className={styles.teamMember}>
                                            <div className={styles.name}>{teamMember.teamMember_id.firstName} {teamMember.teamMember_id.lastName}</div>
                                            <div className={styles.title}>{teamMember.teamMember_id.title}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
        );
    }else {
        return(
        <>
                {teamMembers && teamMembers.length > 0 &&
                <div className={styles.meetTheTeamV3}>
                    <div className={styles.container}>
                        <div className={styles.wrap}>
                            <div className={styles.row1}>
                                <div className={styles.leftContent}>
                                    <div className={styles.leftText}>Meet <br></br>The Team</div>
                                    <div className={styles.greenLine}></div>
                                </div>
                                <div className={styles.rightContent}>
                                    <div className={styles.rightText}>{loanOfficer.meetTheTeamDescription}</div>
                                    <div className={styles.buttonRow}>
                                        <RoundedButton color="greenToBlue" text="More About The Team" destination="https://google.com" />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row2}>
                                {teamMembers.map((teamMember, index) => (
                                    <div className={styles.imgContainer}>
                                        <img src={teamMember?.teamMember_id?.headshot?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={`${teamMember.teamMember_id.firstName} ${teamMember.teamMember_id.lastName}`} />
                                        <div className={styles.teamMember}>
                                            <div className={styles.name}>{teamMember.teamMember_id.firstName} {teamMember.teamMember_id.lastName}</div>
                                            <div className={styles.title}>{teamMember.teamMember_id.title}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
        );
    }
    
}

export default MeetTheTeam;