// extracted by mini-css-extract-plugin
export var container = "stackedInformationalSection-module--container--pYASs";
export var solidBg = "stackedInformationalSection-module--solidBg--YyPL-";
export var stockBg = "stackedInformationalSection-module--stockBg--vubgM";
export var content = "stackedInformationalSection-module--content--WRLxq";
export var sectionCTAContent = "stackedInformationalSection-module--sectionCTAContent--WWDn8";
export var buttonRow = "stackedInformationalSection-module--buttonRow--6Q+UE";
export var sectionTitle = "stackedInformationalSection-module--sectionTitle--kqucT";
export var greenLine = "stackedInformationalSection-module--greenLine--3Q6bH";
export var description = "stackedInformationalSection-module--description--k0HDU";
export var background = "stackedInformationalSection-module--background--u7Mx2";
export var backgroundV2 = "stackedInformationalSection-module--backgroundV2--+QN+-";