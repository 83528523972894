import * as React from "react";
import RoundedButton from '../sub-components/roundedButton';
import * as styles from './loanOfficerHero.module.scss';

const LoanOfficerHero = ({ loanOfficer, version }) => {

  const headshotImage = loanOfficer?.image?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  if (version == 2) {
    return (
      <div className={styles.loanOfficerHeroV2}>
        <div className={styles.heroImage}>
          <div className={[styles.heroContent, styles.container].join(' ')}>
            <div className={styles.headline}>
              <span>Simple. </span>
              <span>Digital. </span>
              <span>Smart. </span>
            </div>
            <div className={styles.imageContainer}>
              <img src={headshotImage} alt={`${loanOfficer.firstName} ${loanOfficer.lastName}`} />
            </div>
          </div>
        </div>
        <div className={styles.informationBanner}>
          <div className={[styles.informationContent, styles.container].join(' ')}>
            <div className={styles.name}>{loanOfficer.firstName} {loanOfficer.lastName}</div>
            <div className={styles.nmlsNumber}>{loanOfficer.title ? loanOfficer.title : "Loan Officer"} - NMLS # {loanOfficer.nmlsNumber}</div>
            <div className={styles.buttonRow}>
              {loanOfficer?.applyLink && <RoundedButton color="greenToBlue" text="Apply Now" destination={loanOfficer.applyLink} />}
              {loanOfficer?.continueApplicationLink && <RoundedButton color="greenToBlue" text="Continue Application" destination={loanOfficer.continueApplicationLink} />}
              {loanOfficer?.currentBookingsLink && <RoundedButton color="greenToBlue" text="Schedule Appointment" destination={loanOfficer.currentBookingsLink} />}
            </div>
          </div>
        </div>
      </div>);
  } else {
    return (
      <div className={styles.loanOfficerHeroV3}>
        <div className={styles.heroImage}>
          <div className={styles.imageContainer}>
            <img src={headshotImage} alt={`${loanOfficer.firstName} ${loanOfficer.lastName}`} />
          </div>
        </div>
        <div className={[styles.heroContent].join(' ')}>
          <div className={styles.loanOfficerDetails}>
            <div className={styles.headline}>
              <span>Simple. </span>
              <span>Digital. </span>
              <span>Smart. </span>
            </div>
            <div className={styles.name}>{loanOfficer.firstName} {loanOfficer.lastName}</div>
            <div className={styles.nmlsNumber}>{loanOfficer.title ? loanOfficer.title : "Loan Officer"} - NMLS # {loanOfficer.nmlsNumber}</div>
          </div>
          <div className={styles.informationBanner}>
            <div className={styles.buttonRow}>
              {loanOfficer?.applyLink && <RoundedButton color="greenToBlue" text="Apply Now" destination={loanOfficer.applyLink} />}
              {loanOfficer?.continueApplicationLink && <RoundedButton color="greenToBlue" text="Continue Application" destination={loanOfficer.continueApplicationLink} />}
              {loanOfficer?.currentBookingsLink && <RoundedButton color="greenToBlue" text="Schedule Appointment" destination={loanOfficer.currentBookingsLink} />}
            </div>
          </div>
        </div>
      </div>);
  }
}

export default LoanOfficerHero;